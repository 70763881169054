.sidebar {
    background-color: #2E4355;
    color: white;
    width: 250px;
    position: fixed;
    height: 100%;
    z-index: 1;
    top: 70px;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
}

.sidebar a {
    color: lightgray;
    text-decoration: none;
}

.sidebar a:hover {
    color: #FFFFFF;
    text-decoration: none;
}

.sidebar a:active {
    color: #FFFFFF;
    text-decoration: none;
}

.no-users-block {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: none;
}

.no-news-block {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: none;
}
