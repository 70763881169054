// Body
$body-bg: #f8fafc;
// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
//$font-size-base: 0.9rem;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$font-color-black: #383838;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$placeholder-grey: #D8D8D8;
//Paddings
$padding-item: 4px;
.p-1 {
  padding: $padding-item !important;
}

.p-2 {
  padding: $padding-item * 2 !important;
}

.p-3 {
  padding: $padding-item * 3 !important;
}

.p-4 {
  padding: $padding-item * 4 !important;
}

.p-5 {
  padding: $padding-item * 5 !important;
}

.p-5 {
  padding: $padding-item * 5 !important;
}

.p-6 {
  padding: $padding-item * 6 !important;
}

//Padding Horizontal
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: $padding-item !important;
  padding-left: $padding-item !important;
}

.px-2 {
  padding-right: $padding-item * 2 !important;
  padding-left: $padding-item * 2 !important;
}

.px-3 {
  padding-right: $padding-item * 2 !important;
  padding-left: $padding-item * 2 !important;
}

.px-4 {
  padding-right: $padding-item * 4 !important;
  padding-left: $padding-item * 4 !important;
}

.px-5 {
  padding-right: $padding-item * 5 !important;
  padding-left: $padding-item * 5 !important;
}

.px-6 {
  padding-right: $padding-item * 6 !important;
  padding-left: $padding-item * 6 !important;
}

//Padding Vertical
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: $padding-item !important;
  padding-bottom: $padding-item !important;
}

.py-2 {
  padding-top: $padding-item * 2 !important;
  padding-bottom: $padding-item * 2 !important;
}

.py-3 {
  padding-top: $padding-item * 3 !important;
  padding-bottom: $padding-item * 3 !important;
}

.py-4 {
  padding-top: $padding-item * 4 !important;
  padding-bottom: $padding-item * 4 !important;
}

.py-5 {
  padding-top: $padding-item * 5 !important;
  padding-bottom: $padding-item * 5 !important;
}

.py-6 {
  padding-top: $padding-item * 6 !important;
  padding-bottom: $padding-item * 6 !important;
}

//Padding Bottom
.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: $padding-item * 1 !important;
}

.pb-2 {
  padding-bottom: $padding-item * 2 !important;
}

.pb-3 {
  padding-bottom: $padding-item * 3 !important;
}

.pb-4 {
  padding-bottom: $padding-item * 4 !important;
}

.pb-5 {
  padding-bottom: $padding-item * 5 !important;
}

.pb-6 {
  padding-bottom: $padding-item * 6 !important;
}

//Padding Top
.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: $padding-item * 1 !important;
}

.pt-2 {
  padding-top: $padding-item * 2 !important;
}

.pt-3 {
  padding-top: $padding-item * 3 !important;
}

.pt-4 {
  padding-top: $padding-item * 4 !important;
}

.pt-5 {
  padding-top: $padding-item * 5 !important;
}

.pt-6 {
  padding-top: $padding-item * 6 !important;
}

//Padding left
.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: $padding-item * 1 !important;
}

.pl-2 {
  padding-left: $padding-item * 2 !important;
}

.pl-3 {
  padding-left: $padding-item * 3 !important;
}

.pl-4 {
  padding-left: $padding-item * 4 !important;
}

.pl-5 {
  padding-left: $padding-item * 5 !important;
}

.pl-6 {
  padding-left: $padding-item * 6 !important;
}

//Padding Right
.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: $padding-item * 1 !important;;
}

.pr-2 {
  padding-right: $padding-item * 2 !important;;
}

.pr-3 {
  padding-right: $padding-item * 3 !important;;
}

.pr-4 {
  padding-right: $padding-item * 4 !important;;
}

.pr-5 {
  padding-right: $padding-item * 5 !important;;
}

.pr-6 {
  padding-right: $padding-item * 6 !important;;
}

//Margins
.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: $padding-item !important;
}

.mr-2 {
  margin-right: $padding-item*2 !important;
}

.mr-3 {
  margin-right: $padding-item*3 !important;
}

.mr-4 {
  margin-right: $padding-item*4 !important;
}

.mr-5 {
  margin-right: $padding-item*5 !important;
}

.mr-6 {
  margin-right: $padding-item*6 !important;
}

//Margin Bottom
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: $padding-item !important;
}

.mb-2 {
  margin-bottom: $padding-item *2 !important;
}

.mb-3 {
  margin-bottom: $padding-item *3 !important;
}

.mb-4 {
  margin-bottom: $padding-item *4 !important;
}

.mb-5 {
  margin-bottom: $padding-item *5 !important;
}

.mb-6 {
  margin-bottom: $padding-item *6 !important;
}

//Margin Left
.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: $padding-item !important;
}

.ml-2 {
  margin-left: $padding-item *2 !important;
}

.ml-3 {
  margin-left: $padding-item *3 !important;
}

.ml-4 {
  margin-left: $padding-item * 4 !important;
}

.ml-5 {
  margin-left: $padding-item *5 !important;
}

.ml-6 {
  margin-left: $padding-item *6 !important;
}

//Fonts
.font-18 {
  font-size: 1.125rem !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-500 {
  font-weight: 500 !important;
}

textarea::-webkit-input-placeholder {
  color: $placeholder-grey;
}

textarea:-moz-placeholder {
  color: $placeholder-grey
}

textarea::-moz-placeholder { /* Firefox 19+ */
  color: $placeholder-grey;
}

textarea:-ms-input-placeholder {
  color: $placeholder-grey;
}

textarea::placeholder {
  color: $placeholder-grey;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder-grey;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $placeholder-grey;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: $placeholder-grey;
}

//Borders
.bb-grey {
  border-bottom: 1px solid $placeholder-grey;
}

// ===== Custom Checkbox ====== //

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px !important;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.2;
  margin-bottom: 0;

}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  z-index: -1;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #D8D8D8;
  border-radius: 1px;
  background: #fff;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #01A6AB;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}