// Fonts
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"');
// Variables
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'variables';
@import "sidebar";

html {
    background-color: #2E4355;

}

body {
    color: $font-color-black;
    font-family: $font-family-sans-serif;
}

h2 {
    font-size: 1.75rem;
}

h5 {
    font-size: 1.125rem;
}

//Badges

.badge {
    padding: $padding-item $padding-item * 2;
}

.badge-private {
    background-color: #01A6AB;
    color: #FFFFFF;
}

.badge-draft {
    background-color: #adb5bd;
}

.badge-published {
    background-color: #5CCF9F;
    color: #FFFFFF;
}

.badge-unpublished {
    background-color: #b21f2d;
    color: #FFFFFF;
}

.badge-admin {
    background-color: #fd7e14;
    color: #FFFFFF;
}

.badge-staff {
    background-color: #01A6AB;
    color: #FFFFFF;
}

//Forms
.label {
    font-weight: 500 !important;
}

.form-control {
    border: 1px solid $placeholder-grey;
    border-radius: 7px;
}

input[type="text"],
select {
    height: 42px;
}

.form-group {
    margin-bottom: 0.75rem;

    label {
        font-size: 1rem;
        margin-bottom: 0.25rem;
        line-height: 1;

    }
}

label {
    font-size: 17px;
    font-weight: bold;
}

.label {
    font-size: 17px;
    font-weight: bold;
}

label p {
    line-height: 1;
}

//Custom Styles
.inner {
    background-color: #FFFFFF;
}

.pages {
    background-color: #D8D8D8;
}

.edit-link {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: none;
    color: black;
}

a:active {
    text-decoration: none;
    color: black;
}

.highlight-block {
    background-color: #3F5D77;
    padding-top: 18px;
    padding-bottom: 1px;
    margin-bottom: 15px;
    box-sizing: border-box;
    width: 100%;
}

.main-content {
    position: fixed;
    top: 70px; /* same height as navbar */
    left: 250px;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    width: auto;
    background-color: #F0EEEE;
    color: $font-color-black;
    padding: 0 16px;
}

.bb-radius {
    border-radius: 0 0 8px 8px;
}

.grayout {
    opacity: 0.4; /* Real browsers */
}

.vertical-middle.table th,
.vertical-middle.table td {
    vertical-align: middle !important;
}

.side-block {
    h5 {
        padding: 0.75rem 1.5rem;
        margin-bottom: 0;
        border-bottom: 1px solid $placeholder-grey;
    }

}

.search-bar {
    width: 200px;
}

//Header

.navbar-brand {

    &.logo {
        height: 45px;
        object-fit: contain;
        padding: 0;
        display: flex;
        justify-content: flex-start;

        img {
            display: block;
            height: 100%;
            object-fit: contain;

        }
    }
}

.navbar {
    background-color: #01A6AB;
    position: absolute;
    width: 100%;
    height: 70px;
}

.dropdown-menu.dropdown-menu-right {
    padding: 0;
    box-shadow: 1px 0 20px 0 #01a6ab6b;
    border: none;
    right: -14px;

    .dropdown-item {
        padding: 0.75rem 1.7rem;
        background: #01A6AB;
        color: #fff;
        transition: all 0.1s;

        &:hover {
            background: #fff;
            color: #01A6AB;
            transition: all 0.1s;
        }
    }
}

.nav-item.dropdown {
    #navbarDropdown {

        b {
            font-family: $font-family-sans-serif;
        }

        &:before {
            display: none;
        }

        &::after {
            border: none;
            content: "\f078";
            top: 2px;
            position: relative;
            font-size: 14px;
        }
    }
}

//Login

.login {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login {
    &.reset-pass {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 450px;
        width: 100%;
        padding: 0;
    }

    &.sign-in {
        max-width: 355px;
        width: 100%;
        padding: 0;
    }

    &.register {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;

        .logo {
            display: flex;
            align-items: center;
            width: fit-content;
            height: auto;
            margin-bottom: 58px;
        }
    }

    .card-header:first-child {
        background: #ffff;
    }

    .form-control {
        height: 42px;
        border-color: #cacaca
    }

    .btn-forgot {
        text-decoration: underline;
        color: $font-color-black;
        font-size: 14px;
    }
}

//Buttons

.btn {
    font-size: 1.125rem;
    border: none;
    border-radius: 6px;

    &.cancel {
        width: 120px;
        background: #E53A3A;
    }

    &-draft {
        width: 155px;
        background: #D8D8D8;
        color: $font-color-black;
    }

    &-publish {
        width: 140px;
        background: #01A6AB;
    }

    &.btn-small {
        font-size: 0.8754rem;
        height: 32px;
        width: 134px;
        line-height: 0.1
    }
}

.btn {
    height: 36px;
    font-size: 1.125rem;
    line-height: 1;

    &.btn-add {
        height: 42px;
        display: flex;
        align-items: center;
        width: 176px;
        justify-content: center;
        border-radius: 6px;
        color: #383838;
        background: #ffffff;
    }
}

.btn-teal {
    background-color: #01A6AB;
    color: white;
    width: 100%;
    line-height: 1;
    font-size: 18px;
    height: 36px;
    border-radius: 6px;
    border: none;
    box-shadow: none;

    &:hover {
        background: #007477;
    }
}

.btn-publish {
    background-color: #01A6AB;
}

.btn-settings {
    width: 165px;
    margin-left: auto;
    margin-right: 10px;
}

.submit-btn {
    padding: 10px;
}

//Custom Toggler

.switch {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 48px;
}

.switch input {
    display: none;
}

.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.slider:before {
    background-color: #768078;
    bottom: -1px;
    content: "";
    height: 25px;
    width: 25px;
    left: -2px;
    position: absolute;
    transition: .4s;
}

input:checked + .slider {
    background-color: #ccc;
}

input:checked + .slider:before {
    transform: translateX(26px);
    background: #53B667;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

//Custom Upload

.img-wrapper--block {
    display: flex;
    flex-direction: column;
}

.img-wrapper--inner {
    width: 100%;
    height: 132px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid #D3D3D3;
    border-radius: 7px;
    overflow: hidden;
    position: relative;

    img {
        height: 110px;
        width: 110px;
        object-fit: cover;
    }

    .custom-file-input {
        position: absolute;
        height: 0;
        width: 100%;
        opacity: 0;
        top: 0;
        left: 0;
    }
}

.image-upload > input {
    display: none;
}

.update-image {
    font-size: 12px;
    display: none;
}

.undo-upload {
    display: none;
    text-decoration: underline;
    color: black;
    font-weight: bold;
}

.remove-image {
    text-decoration: underline;
    color: black;
}

.custom-file {
    width: 100%;
    height: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px solid #D3D3D3;
    border-radius: 7px;

    input {
        -webkit-appearance: none;
    }

    label {
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    .pic-placeholder {
        width: 50px;
        height: 38px;
        margin-bottom: 20px;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .preview-img {
        width: 100%;
        height: 100%;
    }

    .img-top {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    p {
        margin-bottom: 15px;
        font-size: 12px;
        text-align: center;
    }

    .btn-placeholder {
        width: 133px;
        min-height: 32px;
        font-size: 14px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $font-color-black;
        background: #D9D9D9;
        pointer-events: none;
        touch-action: none;

        &:hover {
            background: #b9b6b6;
        }
    }
}

//Notifications

.sent-notification {
    .label {
        margin-bottom: 0;
    }
}

.notification-list {
    display: flex;
    flex-direction: column;
    max-height: 175px;
    overflow: auto;
    padding-top: 13px;

    .notification-list--item {
        display: flex;
        height: auto;
        position: relative;
        padding-bottom: 20px;

        .circle {
            min-width: 16px;
            min-height: 16px;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background: #01A6AB;
            margin-right: 12px;
        }

        .text-block {
            display: flex;
            flex-direction: column;

            .heading {
                font-size: 16px;
                font-weight: 500;
                line-height: 1;
                margin-bottom: 4px;
            }

            .read-more {
                font-size: 16px;
                line-height: 1;
                margin-bottom: 4px;
                color: $font-color-black;
            }

            .date {
                font-size: 12px;
            }

        }

        &:after {
            position: absolute;
            content: '';
            display: block;
            left: 7px;
            top: 5%;
            height: 95%;
            width: 2px;
            background: #01A6AB;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

//CKEditor

.ck-editor__editable_inline {
    min-height: 200px;
}

:root {
    --ck-border-radius: 5px;
}

//Pagination

.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > span {
    color: #01A6AB;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #01A6AB;
    border-color: #01A6AB;
}
